<template>
    <div class="users">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <breads :links="breadLinks"/>
                    <PageHeader :title="$t('Distributors')" :button-caption="$t('Add a distributor')" @pageHeaderButtonClick="addDist"/>
                    <div class="page-content">
                        <TableMain
                            :items="distributors"
                            :fields="fields"
                            @update="updateDist"
                            @getItems="getDists"
                            :hideActions="false"
                            :header-columns="columns"
                            :valuesFromRoute="true"
                            :itemsMeta="distributorsMeta"
                            :preloader="preloader"
                        />
                    </div>
                    <ModalComponent class="modal-distributors" :show="showModal.form" @close="showModal.form = false;" :ask-before-leave="askBeforeLeave" :ask-leave-action="askLeaveAction">
                        <div class="add-dist">
                            <h2 class="typography typography--h2 add-dist__title">{{ isEditForm ? $t('Edit Distributor') : $t('Add a distributor') }}</h2>
                            <FormComponent
                                class="form-distributors"
                                @close="$emit('close')"
                                :model="distForm"
                                :noRedirect="true"
                                :method="isEditForm ? 'put' : 'post'"
                                :error="errorHandler"
                                @success="success" :action="newAddDistAction"
                                @isFormDisable="isFormDisable"
                            >
                                <div class="row align-items">
                                    <div class="col-md-6 col-12">
                                        <div class="mb-3">
                                            <InputComponent :required="true" name="name" v-model="distForm.name" :error="errors.name" :showLabel="true" :placeholder="$t('Name')"/>
                                        </div>
                                         <div class="mb-6">
                                            <InputComponent :required="true" name="custom_id" v-model="distForm.custom_id" :error="errors.custom_id" :showLabel="true" :placeholder="$t('ID')"/>
                                        </div>
                                    </div>
                                    <div class="col-md-6 col-12">
                                        <div class="mb-3 mt-3 mt-sm-0">
                                            <SelectComponent
                                                ref="getcompanies"
                                                @input="companiesChange"
                                                :clearable="false"
                                                :lightblue="true"
                                                :required="true"
                                                :options="companies"
                                                :error="errors.company_id"
                                                :show-label="true"
                                                :placeholder="$t('Company')"
                                                @search="searchCompanies"
                                                :value="distForm.company_id"
                                                name="company_id"/>
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <div class="add-dist__buttons">
                                            <ButtonComponent :disabled="isButtonDisabled" type="submit" class="me-0 me-sm-4 mb-3 mb-sm-0" theme="primary" :caption="isEditForm ? $t('Save') : $t('Add')"/>
                                            <ButtonComponent type="button" theme="bordered" @click="cancelAddNew" :caption="$t('Cancel')" />
                                        </div>
                                    </div>
                                </div>
                            </FormComponent>
                        </div>
                    </ModalComponent>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import ButtonComponent from "@/components/ui/ButtonComponent"
    import ModalComponent from "@/components/ui/ModalComponent"
    import FormComponent from "@/components/ui/forms/FormComponent";
    import SelectComponent from "@/components/ui/forms/SelectComponent"
    import InputComponent from "@/components/ui/forms/InputComponent"
    import PageHeader from "@/components/PageHeader";
    import Breads from "@/components/Breads";
    import TableMain from "@/components/ui/tables/TableMain"

    export default {
        components: { ButtonComponent, TableMain, PageHeader, Breads, ModalComponent, FormComponent, InputComponent, SelectComponent },
        data() {
            return {
                columns: [
                    {
                        id: 1,
                        label: this.$t('ID'),
                        sort: false,
                        attribute: "custom_id",
                    },
                    {
                        id: 2,
                        label: this.$t('Name'),
                        sort: false,
                        attribute: "name",
                    },
                    {
                        id: 3,
                        label: this.$t('Company'),
                        sort: false,
                        attribute: "company.name",
                        urlAttribute: "company_name"
                    },
                ],
                distributors: [],
                preloader: true,
                fields: [
                    {
                        type: "search",
                        by: "custom_id",
                    },
                    {
                        type: "search",
                        by: "name",
                    },
                    {
                        type: "search",
                        by: "company_name",
                    },
                ],
                breadLinks: [
                    {
                        label: this.$t('Distributors'),
                        to: { name: 'CatalogDistributors' }
                    }
                ],
                showModal: {
                    form: false,
                },
                askLeaveAction: {},
                askBeforeLeave: false,
                isEditForm: false,
                distForm: {
                    id: '',
                    custom_id: '',
                    name: '',
                    company_id: '',
                },
                errors: {
                    id: '',
                    name: '',
                    custom_id: '',
                    company_id: '',
                },
                isButtonDisabled: false,
                distributorsMeta: [],
                isCompaniesLoaded: false,
                companies: [],
                distId: ''
            }
        },

        computed: {
            // askBeforeLeave() {
            //     let res = Object.keys(this.distForm).filter(elem => {
            //         if (this.distForm[elem] && this.distForm[elem].length > 0) {
            //             return this.distForm[elem].length > 0
            //         }
            //     });
            //     return !!res.length
            // },
            newAddDistAction() {
                return this.isEditForm ? `/api/v1/distributors/${this.distId}` : `/api/v1/distributors`
            },
        },
        watch:{
            distForm: {
                handler: function(){
                    this.askBeforeLeave = true
                },
                deep: true
            }
        },
        methods: {
            isFormDisable(v) {
                this.isButtonDisabled = v
            },
            addDist() {
                this.clearErrorsAndValues();
                this.isEditForm = false;
                this.showModal.form = true;
                setTimeout(() => this.askBeforeLeave = false, 500)
            },
            async searchCompanies(v) {
                if (!v) {
                    v = undefined
                }
                await this.getCompanies({ name: v })
            },
            async updateDist(id) {
                let updatedDist = this.distributors.filter(u => {
                    return u.id == id
                })[0]
                this.clearErrorsAndValues()

                this.isCompaniesLoaded = false
                await this.getCompanies()

                if (updatedDist?.company && !this.companies.find(x => x.id == updatedDist?.company.id)) {
                    updatedDist.company.label = updatedDist.company.name
                    this.companies.push(updatedDist.company)
                }

                this.distForm = {
                    id: id,
                    custom_id: updatedDist.custom_id,
                    name: updatedDist.name,
                    company_id: updatedDist.company_id,
                },

                this.isEditForm = true;
                this.showModal.form = true
                this.distId = id
                setTimeout(() => this.askBeforeLeave = false, 500)
            },
            clearErrorsAndValues() {
                this.distForm = {
                    id: '',
                    name: '',
                    custom_id: '',
                    company_id: '',
                };

                this.errors = {
                    id: '',
                    name: '',
                    custom_id: '',
                    company_id: '',
                },

                this.isButtonDisabled = false
            },
            async getDists(query = null) {
                if (!query) {
                    if (Object.keys(this.$route.query).length == 0) {
                        this.$router.push({ query: Object.assign({}, {page: 1, sort_by: 'id'}) });
                    }
                    query = this.$route.query
                }
                // this.isDistLoaded = false
                let distributors = await this.ApiService.distributor.getDists(query)
                this.distributorsMeta = distributors.meta
                this.distributors = distributors.data
                this.$nextTick(() => {
                    // this.isDistLoaded = true
                })

                this.preloader = false
            },
            async getCompanies(data = null) {
                let companies = await this.ApiService.company.getCompanies(data)
                this.isCompaniesLoaded = true
                companies.data.map(elem => {
                    elem.label = elem.name
                    return elem
                })
                this.companies = companies.data;
                if (this.$refs.getcompanies) {
                    this.$refs.getcompanies.$forceUpdate();
                }
            },
            cancelAddNew() {
                let res = Object.keys(this.distForm).filter(elem => {
                    if (this.distForm[elem] && this.distForm[elem].length > 0) {
                        return this.distForm[elem].length > 0
                    }
                });
                this.askLeaveAction = { value: !!res.length }
            },
            companiesChange(v) {
                this.distForm.company_id = v;
            },
            success(type) {
                type == 'create' ? this.toast.success(this.$t('Distributor added')) : this.toast.success(this.$t('Information successfully updated'))
                this.showModal.form = false;
                this.getDists();
            },
            errorHandler(errors) {
                this.errors = errors;
            },

        },

        mounted() {
            this.getDists()
            this.getCompanies()
        }
    }
</script>

<style lang="scss">
    .add-dist {
        width: 950px;
        max-width: 100%;

        &__title {
            text-align: center;
            margin-bottom: 30px;
        }

        &__buttons {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 50px;

            @media screen and (max-width: $sm) {
                flex-direction: column;
            }
        }
    }
</style>
